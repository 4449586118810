import { DateTime } from 'luxon'
import { anyNumberPattern, stateAbbreviationPattern } from './regex'

/**
 * Converts a hyphen-separated string to camelCase.
 * @param str The string to convert.
 * @returns The camelCased string.
 */
export const toCamelCase = (str: string): string => {
  return str.replace(/-([a-z])/g, (match) => match.substr(1).toUpperCase())
}

/**
 * Converts a camelCase string to Title Case.
 * @param string The camelCase string to convert.
 * @returns The Title Cased string.
 */
export const camelCaseToTitleCase = (string: string): string => {
  return string
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (match) => match.toUpperCase())
}

/**
 * Converts a camelCase string to snake_case.
 * @param string The camelCase string to convert.
 * @returns The snake_cased string.
 */
export const camelCaseToSnakeCase = (string: string): string => {
  return string
    .replace(/([A-Z])/g, '_$1') // Use an underscore before each uppercase letter
    .toLowerCase() // Convert to lowercase
    .replace(/^_/, ''); // Remove leading underscore if it exists
}

/**
 * Converts a camelCase string to kebab-case
 * @param string The camelCase string to convert.
 * @returns The kebab-cased string.
 */
 export const camelCaseToKebabCase = (string: string): string => {
  return string
    .replace(/([A-Z])/g, '-$1') // Use a hyphen before each uppercase letter
    .toLowerCase() // Convert to lowercase
    .replace(/^-/, ''); // Remove leading hyphen if it exists
}

/**
 * Converts a snake_case or kebab-case string to camelCase.
 * @param string The snake_case or kebab-case string to convert.
 * @returns The camelCased string.
 */
export const snakeToCamel = (string: string): string => {
  return string.replace(/([-_]\w)/g, (g) => g[1].toUpperCase())
}

/**
 * Converts a snake_case string to PascalCase.
 * @param string The snake_case string to convert.
 * @returns The PascalCased string.
 */
export const snakeToPascal = (string: string): string => {
  return string
    .replace(/ /g, '')
    .toLowerCase()
    .split('_')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join('')
}

/**
 * Converts a snake_case string to Title Case.
 * @param string The snake_case string to convert.
 * @returns The Title Cased string.
 */
export const snakeToTitleCase = (string: string): string => {
  return string
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

/**
 * Converts the ending 'y' of a string to 'ie'.
 * @param string The string to modify.
 * @returns The modified string with 'y' at the end replaced by 'ie', if applicable.
 */
export const yToIe = (string: string): string => {
  return string.endsWith('y')
    ? `${string.slice(0, string.length - 1)}ie`
    : string
}

/**
 * Pluralizes a noun based on the count provided. Uses a custom suffix if provided, defaults to 's'.
 * @param count The number of items, used to determine if pluralization should occur.
 * @param noun The noun to pluralize.
 * @param suffix The suffix to append for pluralization. Default is 's'.
 * @returns The pluralized form of the noun, if count is not 1; otherwise, the singular form.
 */
export const pluralize = (count: number, noun: string, suffix: string = 's'): string => {
  if (!noun) {
    return ''
  }

  if (noun.endsWith('s')) {
    suffix = 'es'
    return `${noun}${count !== 1 ? suffix : ''}`
  }
  return `${noun}${count !== 1 ? suffix : ''}`
}

/**
 * Converts a string to Title Case, where the first letter of each word is capitalized.
 * @param string The string to convert.
 * @returns The Title Cased string or an empty string if input is empty.
 */
export const toTitleCase = (string: string): string => {
  if (!string) {
    return '';
  }
  return string
    .split(' ')
    .map(word => word ? word[0].toUpperCase() + word.substr(1).toLowerCase() : '')
    .join(' ');
}


/**
 * Capitalizes the first character of a string.
 * @param string The string to capitalize.
 * @returns The string with the first character capitalized.
 */
export const capitalize = (string: string): string => {
  return string.replace(/^./, (str) => str.toUpperCase())
}
/**
 * Truncates a string to a specified length and optionally tries to avoid cutting off words.
 * @param str The string to truncate.
 * @param n The maximum length of the truncated string, including the ellipsis.
 * @param useWordBoundary A boolean indicating whether to truncate at the last whole word before the limit.
 * @returns The truncated string with ellipsis if needed.
 */
export const truncate = (str: string, n: number, useWordBoundary: boolean): string => {
  if (str.length <= n) {
    return str;
  }
  let subString = str.substr(0, n - 3);  // Adjust for ellipsis
  if (useWordBoundary && subString.lastIndexOf(' ') > 0) {
    subString = subString.substr(0, subString.lastIndexOf(' '));
  }
  return subString + '...';
}

/**
 * Reverses the characters in a string.
 * @param string The string to reverse.
 * @returns The reversed string.
 */
export const reverse = (string: string): string => {
  return [...string].reverse().join('')
}

/**
 * Splits a string into array elements at each point where an uppercase letter occurs.
 * @param string The string to split.
 * @returns An array of strings split at each uppercase letter.
 */
export const splitAtCapitals = (string: string): string[] | null => {
  if (string === '') {
    return null
  }
  const result = string.match(/([A-Z][^A-Z]*)/g)
  return result || [string] // Return original string in an array if no uppercase letters
}

/**
 * Calculates the time difference between a given time and the current time,
 * returning the difference in days, hours, and minutes.
 * @param timeThen The past time in ISO format string.
 * @returns A string describing the time difference in days, hours, and minutes, or null if input is invalid.
 */
export const expirationDelta = (timeThen: string | null): string | null => {
  const timeNow = DateTime.local()
  if (!timeThen) {
    return null
  }

  const timeThenParsed = DateTime.fromISO(timeThen)
  if (!timeThenParsed.isValid) {
    return null
  }

  let differenceInTime = timeThenParsed.diff(timeNow).milliseconds
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24))
  differenceInTime -= differenceInDays * 1000 * 3600 * 24

  const differenceInHours = Math.floor(differenceInTime / (1000 * 3600))
  differenceInTime -= differenceInHours * 1000 * 3600

  const differenceInMinutes = Math.floor(differenceInTime / (1000 * 60))
  return `${differenceInDays}d ${differenceInHours}h ${differenceInMinutes}m`
}

/**
 * Formats a numeric input as a US currency string.
 * @param input The number to format as currency.
 * @returns The formatted currency string, or the input itself if formatting fails.
 */
export const currencyFilter = (input: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  })
  try {
    const result = formatter.format(input)
    return result !== '$NaN' ? result : `${input}`
  } catch {
    return `${input}`
  }
}

/**
 * Maps amenity keys to corresponding icon strings using a case-insensitive match.
 * @param key The key representing the amenity.
 * @returns The icon name corresponding to the given amenity key or a default icon if not found.
 */
export const convertAmenityKeyToIcon = (key: string): string => {
  const lowercaseKey = key.toLowerCase()

  const icons: { [key: string]: string } = {
    wifi: 'wifi',
    luggage: 'card_travel',
    lavatory: 'wc',
    bathroom: 'wc',
    'seat belts': 'seat_belt',
    seatbelts: 'seat_belt',
    'ada compliant': 'accessible',
    ada: 'accessible',
    'tv screens': 'tv',
    tv_screens: 'tv',
    outlets: 'power',
    'leather seats': 'event_seat',
    leather_seats: 'event_seat',
    spab: 'spab',
  }

  return icons[lowercaseKey] ?? 'check_circle'
}

/**
 * Converts an amenity key to an icon string using a case-insensitive match.
 * @param key The key representing the amenity.
 * @returns The icon name corresponding to the given amenity key or a default icon if not found.
 */
export const convertAmenityKeyToCRIcon = (key: string): string => {
  const lowercaseKey = key.toLowerCase()

  const icons: { [key: string]: string } = {
    wifi: 'wifi',
    luggage: 'luggage',
    lavatory: 'wc',
    seatbelts: 'seat_belt',
    ada: 'accessible',
    tv_screens: 'tv',
    outlets: 'power',
    leather_seats: 'event_seat',
    spab: 'spab',
    alcohol_allowed: 'liquor',
  }

  return icons[lowercaseKey] ?? 'check_circle'
}

/**
 * Returns a number with its ordinal suffix (e.g., 1st, 2nd, 3rd, etc.).
 * @param number The number to convert to an ordinal string.
 * @returns The number as a string with its ordinal suffix.
 */
export const getOrdinalForNumber = (number: number): string => {
  let suffix = ['th', 'st', 'nd', 'rd']
  let digit = number % 100
  return number + (suffix[(digit - 20) % 10] || suffix[digit] || suffix[0])
}

/**
 * Converts a kebab-case string to Title Case.
 * @param string The kebab-case string to convert.
 * @returns The Title Cased string.
 */
export const kebabToTitle = (string: string): string => {
  return string.replace(/(-|^)([^-]?)/g, (_, prep, letter) => {
    return (prep && ' ') + letter.toUpperCase()
  })
}

/**
 * Converts a string to kebab-case.
 * @param string The string to convert.
 * @returns The kebab-cased string.
 */
export function toKebab(string: string): string {
  return string
    .split('')
    .map((letter) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`
      }
      return letter
    })
    .join('')
    .trim()
    .replace(/[_\s]+/g, '-')
}

/**
 * Formats a number with commas as thousands separators.
 * @param number The number to format.
 * @returns The formatted number as a string with commas.
 */
export const numberWithCommas = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * Formats a number as a percentage with two decimal places.
 * @param number The number to format as a percentage.
 * @returns The formatted percentage string.
 */
export const twoDecimalPercentage = (number: number): string => {
  return number.toFixed(2) + '%'
}

/**
 * Ensures a URL starts with a protocol (http or https). Adds "http://" if missing.
 * @param url The URL to check and format.
 * @returns The URL with a protocol.
 */
export const getExternalLink = (url: string): string => {
  if (!url.match(/^https?:\/\//i)) {
    url = `http://${url}`
  }
  return url
}

/**
 * Converts an ISO time format (HH:mm:ss) to a 12-hour format with am/pm suffix.
 * @param time The ISO formatted time string.
 * @returns The converted time string in 12-hour format with am/pm suffix.
 */
export const convertISOTimeToDisplayTime = (time: string): string => {
  let split = time.split(':');
  let hour = parseInt(split[0]);

  if (hour < 0 || hour > 23) {
    throw new Error("Invalid hour. Hour must be between 0 and 23.");
  }

  let suffix = '';
  if (hour > 12) {
    suffix = 'pm';
    split[0] = (hour - 12).toString();  // Convert to 12-hour format and remove leading zero
  } else if (hour === 12) {
    suffix = 'pm';  // Handling noon specifically as PM
  } else if (hour === 0) {
    split[0] = '12';  // Handling midnight to show as 12 AM
    suffix = 'am';
  } else {
    suffix = 'am';
    if (hour < 10) {
        split[0] = hour.toString(); // Removes leading zero if the hour is less than 10
    }
  }
  return `${split[0]}:${split[1]}${suffix}`;
}

const th_val: string[] = ['', 'thousand', 'million', 'billion', 'trillion']
const dg_val: string[] = [
  'zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'
]
const tn_val: string[] = [
  'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen',
  'sixteen', 'seventeen', 'eighteen', 'nineteen'
]
const tw_val: string[] = [
  'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
]

/**
 * Converts a numerical value to its English words representation.
 * @param number The number to convert to words.
 * @returns The English words representation of the number or error messages if not valid.
 */
export const numberToString = (number: number | string): string => {
  let numStr = number.toString();
  numStr = numStr.replace(/[, ]/g, '');
  if (isNaN(parseFloat(numStr))) {
    return 'not a number'.trim();  // Ensure there are no trailing spaces
  }
  let x_val = numStr.indexOf('.');
  if (x_val === -1) {
    x_val = numStr.length;
  }
  if (x_val > 15) {
    return 'too big';
  }
  const n_val = numStr.split('');
  let str_val = '';
  let sk_val = 0;
  for (let i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 === 2) {
      if (n_val[i] === '1') {
        str_val += `${tn_val[Number(n_val[i + 1])]} `;
        i++;
        sk_val = 1;
      } else if (n_val[i] !== '0') {
        str_val += `${tw_val[Number(n_val[i]) - 2]} `;
        sk_val = 1;
      }
    } else if (n_val[i] !== '0') {
      str_val += `${dg_val[Number(n_val[i])]} `;
      if ((x_val - i) % 3 === 0) {
        str_val += 'hundred ';
      }
      sk_val = 1;
    }
    if ((x_val - i) % 3 === 1) {
      if (sk_val) {
        str_val += `${th_val[(x_val - i - 1) / 3]} `;
      }
      sk_val = 0;
    }
  }
  if (x_val !== numStr.length) {
    const y_val = numStr.length;
    str_val += 'point ';
    for (let i = x_val + 1; i < y_val; i++) {
      str_val += `${dg_val[Number(n_val[i])]} `;
    }
  }
  return str_val.replace(/\s+/g, ' ').trim();
}

// TODO: need to type the component first
export const formatReservationPickupDestinationText = (reservation): string => {
  const cities = getReservationPickupDestinationCities(reservation)
  return `${cities.pickup} > ${cities.dropoff}`
}

// TODO: need to type the component first
export const addressPretty = (stop): string => {
  const street1 =
    stop?.address?.street1 && stop?.address?.street1 !== ' '
      ? `${stop?.address?.street1?.trim()}, `
      : ''
  const city = stop?.address?.city ? `${stop?.address?.city}, ` : ''
  const state = `${stop?.address?.state} ` || ''
  return `${street1}${city}${state}`
}

/**
 *
 * Formats an address into a pretty string that includes the street1, city, and state.
 * @param street - The string that contains the street information.
 * @param city - The string that contains the city information.
 * @param state - The string that contains the state information.
 * @returns A string with the street1, city, and state of the address.
 */
export const addressPrettyFromAddress = (street: string, city: string, state: string) => {
  const parts = [street, city, state]
    .map(part => part?.trim())
    .filter(Boolean) // Remove any falsy values (e.g., null, undefined, empty strings)
  return parts.join(', ')
}

// TODO: need to type the component first
export const getReservationPickupDestinationCities = (reservation): { pickup: string; dropoff: string } => {
  const pickup = reservation.pickupLocation
    ? reservation.pickupLocation.split(',')[0]
    : cityFromAddressName(reservation.firstPickupAddressName)
  const dropoff = reservation.firstDropoffAddressName
    ? cityFromAddressName(reservation.firstDropoffAddressName)
    : pickup
  return { pickup, dropoff }
}

// TODO: need to type the component first
export const cityFromAddress = (address): string => {
  if (address.city) {
    return address.city
  }
  const city = cityFromAddressName(address.addressName || address.name)
  if (city) {
    return city
  }
  return address.title
}

/**
 * Extracts the city name from a given address string.
 *
 * The function assumes the address format generally follows "Street, City, StateCode, Zip",
 * and that state codes are always in a standard two-letter abbreviation format.
 * Numbers from the address are removed before processing.
 *
 * @param {string} addressName - The full address as a string.
 * @returns {string | null} The city name if present, otherwise null if the address is empty or improperly formatted.
 */
export const cityFromAddressName = (addressName: string): string | null => {
  if (!addressName) {
    return null
  }

  const addressNameSplit = addressName.replace(anyNumberPattern, '').split(',')
  const stateIndex = addressNameSplit.findIndex((string) =>
    stateAbbreviationPattern.test(string)
  )
  return addressNameSplit[stateIndex - 1]
}

/**
 *
 * Formats the pickup and dropoff locations for a quote into a string.
 * @param quote - The quote to get the pickup and dropoff locations from.
 * @param dividerText - The text to use as a divider between the pickup and dropoff locations.
 * @returns A string with the formatted pickup and dropoff locations.
 */
// TODO: need to type the component first
export const formatQuotePickupDestinationText = (quote, dividerText = 'to'): string => {
  const cities = getQuotePickupDestination(quote)
  if (!cities.pickup && !cities.dropoff) {
    return null
  }
  return `${cities.pickup} ${dividerText} ${cities.dropoff}`
}

/**
 * Returns the pickup and dropoff locations for the given quote as strings.
 *
 * @param quote - The quote to get pickup and dropoff locations for.
 * @returns An object with two properties: pickup and dropoff, each representing the pickup and dropoff locations for the quote.
 */
// TODO: need to type the component first
export const getQuotePickupDestination = (quote): { pickup: string; dropoff: string } => {
  const trip = quote?.trips?.[0]
  return getPickupDestinationCitiesFromAddresses(
    trip?.stops?.[0]?.address,
    trip?.stops?.[1]?.address
  )
}

/**
 * Extracts the pickup and dropoff cities from the given addresses.
 *
 * @param address1 - The first address object.
 * @param address2 - The second address object.
 * @returns An object with the `pickup` and `dropoff` cities.
 */
// TODO: need to type the component first
export const getPickupDestinationCitiesFromAddresses = (address1, address2): { pickup: string; dropoff: string } => {
  if (!address1 && !address2) {
    return { pickup: null, dropoff: null }
  }
  let pickup =
    address1?.city ||
    cityFromAddressName(address1?.addressName) ||
    abbrState(address1?.state, 'name')

  const dropoff =
    address2?.city ||
    cityFromAddressName(address2?.addressName) ||
    abbrState(address2?.state, 'name') ||
    pickup

  if (!pickup && !!dropoff) {
    pickup = dropoff
  }

  return { pickup, dropoff }
}

/**
 * Converts a U.S. state name to its corresponding abbreviation, or vice versa.
 *
 * The function requires an input of either a full state name or an abbreviation and a target format.
 * The target format determines whether the output should be an abbreviation ("abbr") or a full state name ("name").
 * The conversion is case-insensitive, but the function returns properly formatted names and abbreviations.
 *
 * @param {string} input - The state name or abbreviation to be converted. The function adjusts for different case inputs automatically.
 * @param {string} to - Specifies the desired output format: "abbr" for abbreviation or "name" for full state name.
 * @returns {string | null} The converted state name or abbreviation. Returns `null` if the input does not match any known state name or abbreviation.
 */
export const abbrState = (input: string, to: string): string | null => {
  if (!input) {
    return null
  }

  const states = [
    ['Arizona', 'AZ'],
    ['Alabama', 'AL'],
    ['Alaska', 'AK'],
    ['Arkansas', 'AR'],
    ['California', 'CA'],
    ['Colorado', 'CO'],
    ['Connecticut', 'CT'],
    ['Delaware', 'DE'],
    ['Florida', 'FL'],
    ['Georgia', 'GA'],
    ['Hawaii', 'HI'],
    ['Idaho', 'ID'],
    ['Illinois', 'IL'],
    ['Indiana', 'IN'],
    ['Iowa', 'IA'],
    ['Kansas', 'KS'],
    ['Kentucky', 'KY'],
    ['Louisiana', 'LA'],
    ['Maine', 'ME'],
    ['Maryland', 'MD'],
    ['Massachusetts', 'MA'],
    ['Michigan', 'MI'],
    ['Minnesota', 'MN'],
    ['Mississippi', 'MS'],
    ['Missouri', 'MO'],
    ['Montana', 'MT'],
    ['Nebraska', 'NE'],
    ['Nevada', 'NV'],
    ['New Hampshire', 'NH'],
    ['New Jersey', 'NJ'],
    ['New Mexico', 'NM'],
    ['New York', 'NY'],
    ['North Carolina', 'NC'],
    ['North Dakota', 'ND'],
    ['Ohio', 'OH'],
    ['Oklahoma', 'OK'],
    ['Oregon', 'OR'],
    ['Pennsylvania', 'PA'],
    ['Rhode Island', 'RI'],
    ['South Carolina', 'SC'],
    ['South Dakota', 'SD'],
    ['Tennessee', 'TN'],
    ['Texas', 'TX'],
    ['Utah', 'UT'],
    ['Vermont', 'VT'],
    ['Virginia', 'VA'],
    ['Washington', 'WA'],
    ['West Virginia', 'WV'],
    ['Wisconsin', 'WI'],
    ['Wyoming', 'WY'],
  ]

  if (to === 'abbr') {
    input = input.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
    for (const element of states) {
      if (element[0] === input) {
        return element[1]
      }
    }
  } else if (to === 'name') {
    input = input.toUpperCase()
    for (const element of states) {
      if (element[1] === input) {
        return element[0]
      }
    }
  }
  return null
}

/**
 * Formats a number to ensure it always displays one decimal place.
 * This function is useful for displaying numerical values consistently with a single decimal point,
 * even if the original number is an integer.
 *
 * @param {number} number - The number to format. If the number is undefined or null, an empty string is returned.
 * @returns {string} The formatted number with one decimal place, or an empty string if the input is null or undefined.
 */
export const alwaysShowTenthsPlace = (number: number): string => {
  if (number === null || number === undefined) {
    return '';
  }
  return number.toFixed(1);
}


/**
 * Checks if the given string is empty or contains only whitespace.
 *
 * This function is helpful for validating input where a non-empty, non-whitespace string is required.
 *
 * @param {string | null} str - The string to check. If the string is null, it is considered empty.
 * @returns {boolean} `true` if the string is empty or only contains whitespace, otherwise `false`.
 */
export const isEmpty = (str: string | null): boolean => {
  return str === null || str.trim() === ''
}
