import { render, staticRenderFns } from "./CRItineraryItem.vue?vue&type=template&id=cd9005e8&scoped=true"
import script from "./CRItineraryItem.vue?vue&type=script&lang=ts"
export * from "./CRItineraryItem.vue?vue&type=script&lang=ts"
import style0 from "./CRItineraryItem.vue?vue&type=style&index=0&id=cd9005e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd9005e8",
  null
  
)

export default component.exports