var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cr-timeline-item d-flex"},[_c('div',{staticClass:"cr-timeline-item__divider position-relative top-0"},[_c('div',{staticClass:"cr-timeline-item__line w-0 h-full position-absolute z-0 top-2 border-l-2 border-y-0 border-r-0 border-gray-subtle-text",class:{
        'border-dashed': _vm.upcoming,
        'border-solid': !_vm.upcoming,
      },style:({
        left: '50%',
        transform: 'translateX(-50%)',
      })}),_vm._t("icon",function(){return [(_vm.loading)?_c('SkeletonBox',{staticClass:"h-16 w-16 border-radius-round"}):_c('img',{staticClass:"h-16 w-16 position-relative top-0",attrs:{"src":_vm.icon}})]})],2),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }